import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Authorizer from '@domui-utils/workflow/authorizer';
import Organization from '@domui-utils/workflow/organization';

export const useFetchAdminAccessRights = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchAdminAccessRights = async (
    clubId: string,
    userData: DynamicData,
    individualId: string | undefined
  ) => {
    try {
      if (clubId) {
        setLoading(true);
        setError(null);

        const wfOrgService = new Organization();
        const wfAuthService = new Authorizer();
        const [clubInfo, operationsAccess] = await Promise.all([
          wfOrgService.fetchOrgDisplayInfo(clubId),
          wfAuthService.fetchOperationsAccess(clubId, userData, individualId),
        ]);

        setData({ clubInfo, operationsAccess });
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchAdminAccessRights };
};
